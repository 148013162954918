import Typography from "@mui/material/Typography";
import * as React from "react";
import {ReactNode} from "react";

export function SectionTitle(props: { children: ReactNode }) {
    return <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
    >
        {props.children}
    </Typography>
}