import * as React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import {AppBar, Button, Toolbar} from "@mui/material";
import { indigo } from '@mui/material/colors';

export default function Header() {
    return <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
            <Typography variant="h6" color="inherit" sx={{ flexGrow: 1, textAlign: "left", fontStyle: "italic", fontWeight: "bold", color: indigo[500], display: { xs: "none", md: "block" } }}>
                OnLogixs
            </Typography>
            <nav>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#solutions"
                    sx={{ my: 1, mx: 1.5 }}
                >
                    Solutions
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#features"
                    sx={{ my: 1, mx: 1.5 }}
                >
                    Features
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="#pricing"
                    sx={{ my: 1, mx: 1.5 }}
                >
                    Pricing
                </Link>
                <Link
                    variant="button"
                    color="text.primary"
                    href="https://help.onlogixs.com"
                    sx={{ my: 1, mx: 1.5 }}
                >
                    Help Center
                </Link>
            </nav>
            <Button href="https://app.onlogixs.com" variant="outlined" sx={{ my: 1, mx: 1.5, display: { xs: "none", md: "block" } }}>
                Login
            </Button>
        </Toolbar>
    </AppBar>;
}