export const data = [
    {
        title: "Fleet and Asset Management",
        problem: "Lack of visibility into the entire fleet's location and availability can lead to inefficiencies and missed opportunities.",
        solution: "Provides real-time tracking and visibility into the fleet and contractor assets using a mobile app and ELD integrations. This improves asset utilization, reduces downtime, and minimizes missed opportunities."
    },
    {
        title: "Dispatching and Bidding Process",
        problem: "Manual dispatching and bidding processes can be time-consuming, error-prone, and hinder communication.",
        solution: "Automates the dispatching process and streamlines bidding by automatically matching relevant loads to units. This leads to faster decision-making, improved communication, and cost savings."
    },
    {
        title: "Providing complete bids' information",
        problem: "Customers may lack information on tender options, leading to dissatisfaction and uncertainty.",
        solution: "Automatically generates and shares detailed information with customers, including ETA, distance, and unit details. This boosts customer satisfaction, trust, and transparency."
    },
    {
        title: "Accounts Receivable",
        problem: "Managing invoices and accounts receivable manually can lead to errors and delays.",
        solution: "Helps companies keep track of all outstanding invoices, ensuring AR is under control. This reduces errors, accelerates payments, and improves cash flow."
    },
    {
        title: "Workflow Automation and Scalability",
        problem: "Manual processes can limit a company's ability to scale efficiently.",
        solution: "Automates workflow processes, allowing companies to focus on expanding their fleet and services instead of hiring additional dispatchers. This boosts scalability and profitability."
    },
    {
        title: "Compliance and Regulatory Requirements",
        problem: "Non-compliance with ELD and other regulatory requirements can lead to fines and legal issues.",
        solution: "Integrates with ELDs and helps companies stay compliant with regulations, tracks all important renewal dates, reducing the risk of fines and legal complications."
    }
]