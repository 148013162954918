import * as React from 'react';

import Box from '@mui/material/Box';
import Image from '../../accets/main-background.png';
import {Button, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";

export default function MainTop() {
    return <Box
        sx={{
            py: 3,
            px: 2,
            height: "300px",
            backgroundImage: `url(${Image})`
        }}
    >
        <Grid container sx={{ height: "100%" }}>
            <Grid item sm={6} xs={12} sx={{ color: "white" }}>
                <Box justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }} display="flex" >
                    <Stack>
                        <Typography variant="h2" component="h1" gutterBottom sx={{ fontWeight: "bold" }}>
                            OnLogixs
                        </Typography>
                        <Typography variant="h5" component="h2" gutterBottom>
                            {'Transportation Management System.'}
                        </Typography>
                        <Typography variant="body1">Take your business to the next level.</Typography>
                        <Button href="https://app.onlogixs.com" variant="text" sx={{ my: 1, mx: 1.5, display: { md: "none" }, color: "white", textDecoration: "underline" }}>
                            Login
                        </Button>
                    </Stack>
                </Box>
            </Grid>
            <Grid item sm={6} sx={{ xs: { display: "none" } }}></Grid>
        </Grid>
    </Box>;
}