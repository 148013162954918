import Bids from "../../accets/bids.png";
import FleetView from "../../accets/fleet-view.png";
import Quotes from "../../accets/quotes.png";
import Radar from "../../accets/radar.png";
import Fleet from "../../accets/fleet.png";
import Customers from "../../accets/customers.png";
import Accounting from "../../accets/accounting.png";
import Owner from "../../accets/owner.png";
import Driver from "../../accets/driver.jpg";

export const data = {
    load: {
        Name: "Load Management",
        Items: [
            {
                title: "Master Logistics with Our Load Manager",
                description: "Are you tired of the complexity and chaos in managing your logistics and transportation needs? Look no further! Our Transportation Management System (TMS) is here to revolutionize the way you handle your loads.",
                highlights: [
                    "Streamline load assignments, tracking, and reporting within a single, user-friendly platform.",
                    "Keep a constant eye on your entire transportation network with real-time tracking, alerts, and analytics."
                ],
                image: Radar
            },
            {
                title: "Real-Time Bid Management for Unmatched Efficiency!",
                description: "Say goodbye to the complexities of load bidding and scheduling. Welcome to a new era of logistics management with our state-of-the-art Bid Management feature.",
                highlights: [
                    "Experience the power of instant bid responses from contractors. No more waiting – get bids as soon as they're submitted!",
                    "Simplify your load management! Post your loads seamlessly and receive competitive bids from qualified contractors instantly.",
                    "Our smart algorithms match loads with the right contractors, ensuring optimal matches for both parties.",
                    "Radar connects with external load boards like Sylectus to bring a world of loads right to your fingertips.",
                    "Say goodbye to manual load searches. Radar matches available loads with your assets, giving you real-time opportunities.",
                ],
                image: Bids
            },
            {
                title: "Power of Fleet Visibility",
                description: "Explore our Fleet View, for real-time location tracking, ELD integration, and a mobile app that empowers you to take charge!.",
                highlights: [
                    "Get an instant, bird's-eye view of your fleet's current locations. Pinpoint your assets and contractors on an interactive map, updated in real time.",
                    "Seamlessly incorporate Electronic Logging Devices (ELDs) to ensure compliance and safety while keeping tabs on your fleet.",
                    "Effortlessly identify which assets and contractors are available, helping you make smarter, real-time decisions.",
                    "Make data-driven decisions and optimize your operations based on real-time insights."
                ],
                image: FleetView
            },
            {
                title: "Quotes For Customer Loads",
                description: "Meet our Quotes feature, a powerful tool that simplifies quoting, accelerates decision-making, and instantly makes loads available to all matched contractors' assets.",
                highlights: [
                    "Creating and managing quotes has never been easier. Our platform streamlines the entire quoting process.",
                    "Quotes are made available to all matched contractors' assets in real time, enabling quick decision-making."
                ],
                image: Quotes
            },
        ]
    },
    management: {
        Name: "Management",
        Items: [
            {
                title: "Unleash the Power of Fleet Management",
                description: "Introducing our Fleet Management feature - the ultimate tool to manage assets, drivers, and contractors seamlessly.",
                highlights: [
                    "Keep your fleet in perfect order. Manage your assets, both owned and contractors', effortlessly.",
                    "Stay on top of driver management with access to essential documents such as driver licenses and medical exam expiration dates.",
                    "Streamline contractor management, ensuring clarity, transparency, and efficiency.",
                    "Effortlessly track essential documents, ensuring that your fleet remains compliant and up-to-date."
                ],
                image: Fleet
            },
            {
                title: "Contact Management",
                description: "Contact Management feature - the ultimate tool for managing customers, contacts, agreements, and collaborative notes seamlessly..",
                highlights: [
                    "Maintain comprehensive profiles for your customers, allowing you to understand and serve them better.",
                    "Store all contact details and relevant information for key contacts within your customer organizations.",
                    "Have related agreements on file for quick reference and legal compliance.",
                    "Create and share notes that are visible for your team, promoting collaboration and transparency."
                ],
                image: Customers
            }
        ]
    },
    mobile: {
        Name: "Mobile",
        Items: [
            {
                title: "Fleet Owner App",
                description: "Allow your contractors to take full control of their assets by managing assets and sending bids instantly.",
                highlights: [
                    "Keep your fleet at your fingertips. Manage assets' availability, locations, and maintenance details with ease.",
                    "Receive matched loads for your assets and bid on them instantly, increasing your opportunities and revenue potential.",
                    "With our app, bidding on loads is a breeze. Respond to customer requests without delay."
                ],
                image: Owner
            },
            {
                title: "Driver App",
                description: "Are you ready to put your drivers in control and provide the best visibility for your team and customers? Discover our Driver Mobile App feature - the ultimate tool for drivers to manage their availability and track their real-time location.",
                highlights: [
                    "Drivers can easily manage their availability, ensuring efficient scheduling and load assignments.",
                    "Keep track of your drivers' real-time location, enhancing visibility and transparency.",
                    "Foster real-time communication between drivers, your team, and customers."
                ],
                image: Driver
            },
        ]
    },
    accounting: {
        Name: "Accounting",
        Items: [
            {
                title: "Streamline Invoicing with AR Management",
                description: "Discover our AR feature - the tool that empowers you to create invoices, record communications, track account receivables, and manage payments seamlessly.",
                highlights: [
                    "Create professional invoices for completed loads with just a few clicks.",
                    "Record and track all communications with customers related to invoicing and payments.",
                    "Stay on top of account receivables, monitoring outstanding payments and ensuring timely collections.",
                    "Record and manage payments efficiently, maintaining accurate financial records."
                ],
                image: Accounting
            }
        ]
    }
}