import * as React from 'react';

import {Divider, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {indigo} from "@mui/material/colors";
import Box from "@mui/material/Box";
import EmblaCarousel from "../carousel/carousel";

export interface FeatureItemProps {
    title: string;
    description: string;
    highlights: string[];
    image: string,
    imageLeft: boolean
}

export interface FeatureSectionProps {
    title: string;
    items: any[];
}

export const DividerSection = () => <Box justifyContent={"center"} alignItems={"center"} display="flex"><Divider variant="middle" sx={{ width: "33%", backgroundColor: indigo[100] }} textAlign={"center"} /></Box>;

export const Item = (props: FeatureItemProps) => {
    let image = <Grid item xs={6} md={4}>
        <img src={props.image} alt={"feature"} width="80%"/>
    </Grid>;
    let content = <Grid item xs={6} md={4} justifyContent={"center"} alignItems={"center"} display="flex">
        <Stack>
            <Typography variant="h5" color="inherit" sx={{ flexGrow: 1, textAlign: "left", fontStyle: "italic", color: indigo[500], pb: 2 }}>
                {props.title}
            </Typography>
            <Typography variant="subtitle1" color="inherit" sx={{ flexGrow: 1, textAlign: "left" }}>{props.description} </Typography>
            {props.highlights?.length > 0 && <Typography variant="subtitle2" color="inherit" sx={{ flexGrow: 1, textAlign: "left" }}>
                <Stack sx={{ pt: 2 }}>
                    {props.highlights.map(((x, key) =>
                        <b key={key}>• {x}</b>))}
                </Stack>
            </Typography>}
        </Stack>
    </Grid>;

    return <Grid container>

        <Grid item md={2}></Grid>
            { props.imageLeft ? image : content }
            { props.imageLeft ? content : image }
        <Grid item md={2}></Grid>
    </Grid>;
}
export const FeatureSection = (props: FeatureSectionProps) => {
    let items = props.items.map((x, i) => {
        return <React.Fragment key={i}>
            <Item
                title={x.title}
                description={x.description}
                highlights={x.highlights}
                image={x.image}
                imageLeft={i%2 === 0}
            />
        </React.Fragment>
    });

    return <Box id={"features"}>
        <Typography variant="h4" color="inherit" sx={{ textAlign: "center", fontStyle: "italic", color: indigo[500], pb: 4, pt: 4 }}>
            {props.title}
        </Typography>
        <DividerSection/>
        <EmblaCarousel slides={items} options={{ loop: true }} />
    </Box>;
}