import React, { useCallback } from 'react'
import useEmblaCarousel, {
    EmblaOptionsType,
    EmblaCarouselType
} from 'embla-carousel-react'
import {
    PrevButton,
    NextButton,
    usePrevNextButtons
} from './arrow-buttons'
import { DotButton, useDotButton } from './dot-button'
import Autoplay from 'embla-carousel-autoplay'
import {Box} from "@mui/material";

type PropType = {
    slides: React.ReactNode[]
    options?: EmblaOptionsType
}

const EmblaCarousel: React.FC<PropType> = (props) => {
    const { slides, options } = props
    const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()])

    const onButtonClick = useCallback((emblaApi: EmblaCarouselType) => {
        const { autoplay } = emblaApi.plugins()
        if (!autoplay) return
        if (autoplay.options.stopOnInteraction !== false) autoplay.stop()
    }, [])

    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
        emblaApi,
        onButtonClick
    )

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi, onButtonClick)

    return (
        <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((node, index) => (
                        <div className="embla__slide" key={index}>
                            <React.Fragment key={index}>{node}</React.Fragment>
                        </div>
                    ))}
                </div>
            </div>

            {slides.length > 1 && <Box className="embla__buttons embla__buttons_left" sx={{ display: { xs: "none", md: "block" } }}>
                <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
            </Box>}

            {slides.length > 1 && <Box className="embla__buttons embla__buttons_right" sx={{ display: { xs: "none", md: "block" } }}>
                <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
            </Box>}

            {slides.length > 1 && <div className="embla__dots">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={'embla__dot'.concat(
                            index === selectedIndex ? ' embla__dot--selected' : ''
                        )}
                    />
                ))}
            </div>}
        </div>
    )
}

export default EmblaCarousel