import React from 'react';
import './App.css';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Footer from "./components/footer/StickyFooter";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Header from "./components/header/Header";
import MainTop from "./components/main/top";
import {DividerSection, FeatureSection} from "./components/feature/item";
import {data} from "./components/feature/data";
import Pricing from "./components/pricing/pricing";
import {Problems} from "./components/problems/main";
import {SectionTitle} from "./components/common/text";

import './css/embla.css'

/*const DividerSection = () => <Box justifyContent={"center"} alignItems={"center"} display="flex"><Divider orientation={"vertical"} flexItem sx={{ height: "100px", width: "1px", backgroundColor: indigo[100] }} /></Box>;*/

function App() {
  return (
    <div className="App">
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
            }}
        >
            <CssBaseline />
            <Header/>
            <MainTop/>
            <Box sx={{ pt: 5 }}></Box>
            <Problems/>
            <Box sx={{ pt: 10 }}>
                <DividerSection/>
            </Box>
            <Box sx={{ pt: 5 }}></Box>
            <SectionTitle>Features</SectionTitle>
            <FeatureSection title={data.load.Name} items={data.load.Items}/>
            <FeatureSection title={data.management.Name} items={data.management.Items}/>
            <FeatureSection title={data.mobile.Name} items={data.mobile.Items}/>
            <FeatureSection title={data.accounting.Name} items={data.accounting.Items}/>
            <Box sx={{ pd: 10, pt: 10 }}>
                <DividerSection/>
            </Box>
            <Pricing/>
            <Footer/>
        </Box>
    </div>
  );
}

export default App;
