import * as React from "react";
import {SectionTitle} from "../common/text";
import Box from "@mui/material/Box";
import {Card, CardContent, Grid, Typography} from "@mui/material";
import {blueGrey, indigo} from "@mui/material/colors";
import {data} from "./data";
import EmblaCarousel from "../carousel/carousel";

interface ProblemProps {
    title: string;
    problem: string;
    solution: string;
}

const Problem = (props: ProblemProps) => {
    return <Grid container>
        <Grid item md={2}></Grid>
        <Grid item xs={12} md={8}>
            <Card sx={{ m: 5, backgroundColor: blueGrey[50] }}>
                <CardContent>
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1, fontStyle: "italic", color: indigo[500], pb: 2 }}>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.problem}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ pt: 2, fontWeight: "bold" }}>
                        {props.solution}
                    </Typography>
                    {/*<CardActions>
                    <Button size="small">Learn More</Button>
                </CardActions>*/}
                </CardContent>
            </Card>
        </Grid>
        <Grid item md={2}></Grid>
    </Grid>;
}

export function Problems() {
    let items = data.map((x, i) => {
        return <Problem key={i} title={x.title} problem={x.problem} solution={x.solution} />;
    });

    return <React.Fragment>
        <div id={"solutions"}><SectionTitle>Solutions</SectionTitle></div>
        <Box sx={{ pt: 5 }}></Box>
        <EmblaCarousel slides={items} options={{ loop: true }} />
    </React.Fragment>
}